import {
    IMenu,
    ITheme,
    ICompanyInfor,
    IHomeMenu,
    ILeftMenu,
    IIntegration,
    IOnboardings,
    ISite,
} from "interfaces";
import AUApi from "services/AUApi";
import { makeObservable, observable, action, runInAction, toJS } from "mobx";

export const AssetKeys = [
    { id: "homeImage", name: "Home Image", type: "image" },
    { id: "loadingLottie", name: "Loading Animation(lottie)" },
    { id: "banner", name: "Banner Video", type: "video" },
    { id: "logo", name: "Logo", type: "image" },
    { id: "menuBack", name: "Menu Background", type: "image" },
    { id: "serviceImg", name: "Service Background", type: "image" },
    { id: "companyImg", name: "Company Background", type: "image" },
    { id: "emptyCar", name: "Empty Car", type: "image" },
    { id: "recoveryImg", name: "Recovery Image", type: "image" },
    { id: "serviceOptionImg", name: "Service Option Image", type: "image" },
];
export const ColorKeys = [
    { id: "green", name: "Green" },
    { id: "primary", name: "Primary" },
    { id: "background", name: "Background" },
    { id: "text", name: "Text" },
    { id: "white", name: "White" },
    { id: "grey", name: "Grey" },
    { id: "blue", name: "Blue" },
    { id: "main", name: "Main" },
    { id: "transparent", name: "Transparent" },
    { id: "lightGreen", name: "Light Green" },
    { id: "success", name: "Success" },
    { id: "lightGrey", name: "Light Grey" },
    { id: "yellow", name: "Yellow" },
    { id: "red", name: "Red" },
    { id: "pink", name: "Pink" },
    { id: "purple", name: "Purple" },
];

class MainStore {
    loading = false;
    message = "";
    theme: ITheme = {
        assets: {},
        colors: {},
    };

    menus: IMenu = {
        homeMenu: [],
        leftMenu: [],
    };

    integrations: IIntegration[] = [];

    onboardings: IOnboardings = {
        cards: [],
        createdAt: 0,
        visible: true,
    };

    companyLists: ICompanyInfor[] = [];

    siteLists: Array<ISite> = [];

    constructor() {
        makeObservable(this, {
            theme: observable,
            menus: observable,
            companyLists: observable,
            siteLists: observable,
            integrations: observable,
            onboardings: observable,
            loading: observable,
            message: observable,

            fetchTheme: action,
            fetchCompany: action,
            fetchSites: action,
            updateTheme: action,
            fetchMenus: action,
            updateMenus: action,
            updateIntegration: action,
            addIntegration: action,
            fetchIntegrations: action,
            fetchOnboarding: action,
            createOnboarding: action,
            updateCompanyInfor: action,
            createCompanyInfor: action,
            addSite: action,
            editSite: action,
            deleteSite: action,
        });
    }

    fetchCompany() {
        this.loading = true;
        AUApi.fetchCompany().then((res: any) => {
            if (res.success) {
                this.companyLists = res.result ?? ([] as ICompanyInfor[]);
                this.loading = false;
            }
            this.loading = false;
            return this.companyLists;
        });
    }

    updateCompanyInfor(company: ICompanyInfor, id: string) {
        this.loading = true;
        AUApi.updateCompanyInfor(company, id)
            .then((res: any) => {
                this.message = res.result.message;
                this.loading = false;
            })
            .catch((err: any) => {
                console.log(err);
                this.loading = false;
            });
    }

    async createCompanyInfor(company: ICompanyInfor) {
        this.loading = true;
        const res = await AUApi.createCompanyInfor(company) as any;
        this.loading = false;
        return res;
    }

    async addSite(siteData: any) {
        this.loading = true;
        try {
            const res = await AUApi.addSite(siteData) as any;
            this.loading = false;
            if (res.success) {
                runInAction(() => {
                    this.siteLists.push(res.result);
                });
                return { success: true };
            }
        } catch (err: any) {
            console.log(err);
            this.loading = false;
            return { success: false, message: err.response?.data?.message };
        }
        return { success: false, message: "Something went wrong!" };
    }

    async editSite(siteData: any, id: string) {
        this.loading = true;
        try {
            const res = await AUApi.editSite(siteData, id) as any;
            this.loading = false;
            if (res.success) {
                runInAction(() => {
                    this.siteLists = this.siteLists.map((site) => {
                        if (site.id === id) {
                            return res.result;
                        }
                        return site;
                    });
                });
                return { success: true };
            }
        } catch (err: any) {
            console.log(err);
            this.loading = false;
            return { success: false, message: err.response?.data?.message };
        }
        return { success: false, message: "Something went wrong!" };
    }

    deleteSite(id: string) {
        this.loading = true;
        AUApi.deleteSite(id).then((res: any) => {
            this.loading = false;
            if (res.success) {
                runInAction(() => {
                    this.siteLists = this.siteLists.filter(
                        (site) => site.id !== id
                    );
                });
            }
        });
    }

    async deleteCompanyInfor(code: string) {
        this.loading = true;
        await AUApi.deleteCompanyInfor(code);
        this.loading = false;
    }

    async fetchSites(companyCode: string) {
        this.loading = true;
        const response: any = await AUApi.fetchSites(companyCode);
        this.loading = false;
        if (response.success) {
            this.siteLists = response.result;
        }
        return response;
    }

    /*------------------------   Theme crud  -----------------*/
    fetchTheme(companyId: string, siteCode: string) {
        this.loading = true;

        AUApi.fetchTheme(companyId, siteCode).then((res: any) => {
            console.log(res);
            if (res.success) {
                this.theme = res.result ?? ({} as ITheme);
            }
            this.loading = false;
        });
    }

    updateTheme(theme: ITheme, companyId: string, siteCode: string) {
        this.loading = true;
        AUApi.updateTheme(theme, companyId, siteCode)
            .then((res: any) => {
                this.loading = false;
            })
            .catch((err: any) => {
                console.log(err);
                this.loading = false;
            });
    }

    /*------------------------   Menus crud  -----------------*/
    async fetchMenus(companyId: string, siteCode: string) {
        this.loading = true;
        const response: any = await AUApi.fetchMenus(companyId, siteCode);
        if (response.success) {
            this.menus = {
                homeMenu: response.result?.homeMenu ?? ([] as IHomeMenu[]),
                leftMenu: response.result?.leftMenu ?? ([] as ILeftMenu[]),
            };
            this.loading = false;
        }
    }

    updateMenus(menus: IMenu, companyId: string, siteCode: string) {
        this.loading = true;
        AUApi.updateMenus(menus, companyId, siteCode)
            .then((res: any) => {
                this.loading = false;
            })
            .catch((err: any) => {
                console.log(err);
                this.loading = false;
            });
    }

    /*------------------------   integration crud  -----------------*/
    addIntegration(companyId: string, siteCode: string, integration: IIntegration) {
        this.integrations.push(integration);
        this.updateIntegration(companyId, siteCode);
    }

    fetchIntegrations(companyId: string, siteCode: string) {
        this.loading = true;
        AUApi.fetchIntegrations(companyId, siteCode).then((res: any) => {
            if (res.success) {
                this.integrations =
                    res.result.integrations ?? ([] as IIntegration[]);
            }
            this.loading = false;
        });
    }

    updateIntegration(
        companyCode: string,
        siteCode: string
    ) {
        this.loading = true;
        console.log(toJS(this.integrations), companyCode, siteCode);
        AUApi.createIntegration(toJS(this.integrations), companyCode, siteCode)
            .then((res: any) => {
                this.loading = false;
            })
            .catch((err: any) => {
                console.log(err);
                this.loading = false;
            });
    }

    /*------------------------   onboarding crud  -----------------*/
    async fetchOnboarding(companyId: string, siteCode: string) {
        this.loading = true;
        const response: any = await AUApi.fetchOnboarding(companyId, siteCode);
        if (response.success) {
            this.onboardings = response.result ?? ({} as IOnboardings);
            this.loading = false;
        }
    }

    createOnboarding(
        onboardings: IOnboardings,
        companyId: string,
        siteCode: string
    ) {
        this.loading = true;
        AUApi.createOnboarding(onboardings, companyId, siteCode)
            .then((res: any) => {
                this.loading = false;
            })
            .catch((err: any) => {
                console.log(err);
                this.loading = false;
            });
    }
    /*-----------------------------------------*/
    get isCompanyIn() {
        return !!this.companyLists;
    }
}

const mainStore = new MainStore();
export default mainStore;
