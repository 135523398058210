import { LoginRequest } from "interfaces";
import AUApi from "services/AUApi";
import { makeObservable, observable, action } from 'mobx';

class AuthStore {

    loading = false;
    authUser: any = {};

    constructor() {
        makeObservable(this, {
            authUser: observable,

            fetchAuthUser: action,
        });

        this.init();
    }

    init() {
        const accessToken = sessionStorage.getItem('accessToken');
        const strUser = sessionStorage.getItem('authUser');
        if (accessToken && strUser) {
            this.authUser = JSON.parse(strUser);
            AUApi.setToken(accessToken);
        }
    }

    async fetchAuthUser(logindata: LoginRequest) {
        return AUApi.fetchAuthUser(logindata).then((res: any) => {
            if (res.success) {
                AUApi.setToken(res.accessToken)
                sessionStorage.setItem("authUser", JSON.stringify(res.user));
                sessionStorage.setItem('accessToken', res.accessToken)
                this.authUser = res.user;
            }
            return res;
        });
    }

    async logOut() {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('authUser');
        this.authUser = {};
    }

    get isLoggedIn() {
        return !!this.authUser.code;
    }
}

const authStore = new AuthStore();
export default authStore;