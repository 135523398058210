import {
  InputBox,
  Button,
  Typography,
  CheckBox,
} from "awantunai-design-system";
import { useEffect, useState, ChangeEvent } from "react";
import CarouselPage from "./CarouselPage";
import loginlogo from "../../assets/images/loginlogo.png";
import styled from "styled-components";
import AuthStore from "store/AuthStore";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
interface FormType {
  companyCode: string;
  password: string;
}

const LoginView = () => {
  const [formData, setFormData] = useState<FormType>({
    companyCode: "",
    password: "",
  });
  const [ischecked, setIschecked] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorAlert, setErrorAlert] = useState({
    companyCode: "",
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const companyLoginSubmit = async () => {
    if (!formData.companyCode) {
      updateErrorMessage("companyCode", "Required field");
    }

    if (!formData.password) {
      updateErrorMessage("password", "Required field");
    }

    if (!formData.companyCode || !formData.password || loading) return;

    setLoading(true);
    const res_data: any = await AuthStore.fetchAuthUser(formData);
    if (res_data.success === false) {
      navigate("/login", { replace: true });
      setErrorMsg(res_data.message);
      setLoading(false);
    } else {
      setFormData({
        companyCode: "",
        password: "",
      });
      navigate("/", { replace: true });
      setLoading(false);
    }

    if (ischecked && formData.companyCode !== "") {
      sessionStorage.companyCode = formData.companyCode;
      //sessionStorage.password = formData.password; //never store plain text password
      sessionStorage.checkbox = ischecked;
    }
  };

  // validation of form values

  const updateErrorMessage = (key: string, value: string) => {
    setErrorAlert((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    updateErrorMessage(e.target.name, "");
  };

  const onChangeCheckbox = (value: boolean) => {
    setIschecked(value);
  };

  useEffect(() => {
    if (sessionStorage.checkbox && sessionStorage.companyCode !== "") {
      setIschecked(true);
      setFormData({
        companyCode: sessionStorage.companyCode,
        password: sessionStorage.password,
      });
    }
  }, []);
  return (
    <Container>
      <div className="auth-page">
        <div className="auth-full-page-content">
          <div className="auth-padding-content p-sm-5">
            <div className="d-flex flex-column h-100">
              <div className="auth-content my-auto">
                <div className="form-header">
                  <LogoImg className="avatar-edit" src={loginlogo} />
                  <Typography variant="h5">Welcome Back!</Typography>
                  <Typography variant="body3">
                    Sign in to continue to AU.
                  </Typography>
                </div>
                {/* {COMPANY_LOGIN && ( */}
                <div className="form-group">
                  <InputBox
                    label="E-Mail"
                    name="companyCode"
                    prefIcon="EmailSuccessfully"
                    value={formData.companyCode}
                    onChange={onChange}
                    helpText={errorAlert.companyCode}
                    status={errorAlert.companyCode !== "" ? "error" : "normal"}
                    placeholder="Enter Username"
                  />
                  <InputBox
                    label="Password"
                    name="password"
                    prefIcon="Unlock"
                    value={formData.password}
                    onChange={onChange}
                    helpText={errorAlert.password}
                    status={errorAlert.password !== "" ? "error" : "normal"}
                    placeholder="Enter Password"
                    type="password"
                  />
                  <CheckBox
                    label="Remember me"
                    checked={ischecked ? 1 : 0}
                    onClick={() => onChangeCheckbox(!ischecked)}
                  />
                  <Button
                    title={"Login"}
                    disabled={loading}
                    onClick={companyLoginSubmit}
                    variant="primary"
                    size="small"
                  />
                </div>
                <div className="footer">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="24"
                    visible={loading}
                  />
                  {errorMsg && !loading ? (
                    <Typography color="error-dark" variant="body3">
                      {errorMsg}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CarouselPage />
      </div>
    </Container>
  );
};

export default LoginView;
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  .auth-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    .auth-full-page-content {
      flex-shrink: 0;
      width: 33%;
      height: 100vh;
      position: relative;
      background-color: white;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-top: var(--bs-gutter-y);
    }
    @media (max-width: 768px) {
      .auth-full-page-content {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
      }
    }
    @media (max-width: 576px) {
      .auth-full-page-content {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
      }
    }
  }
  .auth-padding-content {
    min-height: 100vh;
    height: 100vh;
    padding-right: 40px;
    padding-left: 40px;

    .form-header {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 30px;
      gap: 10px;

      div {
        display: block !important;
        text-align: center !important;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      .form-check {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }

    .footer {
      text-align: center;
    }
  }
  .w-100 {
    height: 100vh;
    width: 100% !important;
  }
  .w-70 {
    height: 100vh;
    width: 70% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .input-body input {
    height: 50%;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
    padding-top: 100px;
  }
  .text-center {
    text-align: center !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
    font-size: 1.09375rem;
  }
  .text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important;
  }
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .pt-3 {
    padding-top: 2rem;
    text-align: center;
  }
  .waves-effect {
    background-color: #1d2d59;
    color: white;
    border-radius: 7px;
    border: none;
    cursor: pointer;
  }
  .waves-effect:hover {
    background-color: #3e8e41;
  }
  .error {
    border: 2px;
    background-color: lightcoral;
    border-radius: 4px;
    height: 30px;
    text-align: center;
    padding-top: 15px;
  }
  .error-text {
    color: red;
    font-size: 12px;
  }
  @media (max-width: 576px) {
    .p-sm-5 {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
`;

const LogoImg = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;
