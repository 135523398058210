export const screen_datas = [
    {
        label: 'Home',
        value: 'Home'
    },
    {
        label: 'Inbox',
        value: 'Inbox'
    },
    {
        label: 'Mycar',
        value: 'Mycar'
    },
    {
        label: 'Profile',
        value: 'Profile'
    },
    {
        label: 'Calendar',
        value: 'Calendar'
    },
    {
        label: 'Recovery',
        value: 'Recovery'
    },
    {
        label: 'ShareLocation',
        value: 'ShareLocation'
    },
    {
        label: 'HistoryDocument',
        value: 'HistoryDocument'
    },
    {
        label: 'DocumentDetail',
        value: 'DocumentDetail'
    },
    {
        label: 'Fuel',
        value: 'Fuel'
    },
    {
        label: 'LevelOfCover',
        value: 'LevelOfCover'
    },
    {
        label: 'MakeAClaim',
        value: 'MakeAClaim'
    },
    {
        label: 'YourCover',
        value: 'YourCover'
    },
    {
        label: 'WarrantyArea',
        value: 'WarrantyArea'
    },
    {
        label: 'VehicleInfo',
        value: 'VehicleInfo'
    },
    {
        label: 'Customise',
        value: 'Customise'
    },
]