import { useEffect } from "react";
import { Typography, Icons, ThemeColors, Button } from "awantunai-design-system";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { styled } from "styled-components";
import AuthStore from "store/AuthStore";
import mainStore from "store/MainStore";
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom';
// import { IcompanyArray } from "interfaces";

interface IMenuItem {
    title: string;
    link: string;
    selected?: boolean;
    onClick?: (e?: any) => void;
    onExtraAction?: (e?: any) => void;
    icon: keyof typeof Icons;
}


const MenuItem = ({
    title,
    link,
    selected,
    onClick,
    onExtraAction,
    icon = 'Right'
}: IMenuItem) => {
    const RightIcon = Icons[icon] as any;
    return (
        <Link to={link} className={selected ? "selected" : ""} onClick={onClick}>
            <Typography
                variant="h6"
                color={selected ? 'success-dark' : 'secondary-light'}
            >
                {title}
            </Typography>
            {onExtraAction ?
                <Button
                    icon={icon}
                    variant="secondary"
                    size="small"
                    onClick={onExtraAction}
                /> :
                <RightIcon
                    theme="two-tone"
                    fill={[ThemeColors[selected ? 'success-dark' : 'secondary-light'], 'transparent']}
                    size={24}
                />}
        </Link>
    )
}

const LeftMenu = () => {
    const location = useLocation();
    const { id, companyId, siteCode } = useParams();
    const navigate = useNavigate();
    let companyLink: IMenuItem[] = [];

    const logOut = (e: any) => {
        e.preventDefault();
        AuthStore.logOut();
    };

    const companyEdit = (e: any, idx: string) => {
        e.preventDefault();
        navigate(`/company/edit/${idx}`, { replace: true });
    }

    companyLink = mainStore.companyLists.map((item: any, index: number) => ({
        "title": item.id,
        "link": `/company/${item.id}`,
        "icon": "Edit",
        onExtraAction: (e) => companyEdit(e, item.id)
    }));
    companyLink.push(
        {
            "title": "Add New Company",
            "link": "/company/edit/add",
            "icon": "Add",
            onExtraAction: (e) => companyEdit(e, 'add')
        },
        {
            title: "Log out",
            link: "#",
            icon: "Logout",
            onClick: logOut
        }
    )

    // const handleClick = (e: any) => {
    //     e.preventDefault();
    //     const come_back = mainStore.companyLists[0].id;
    //     if (come_back ?? '') {
    //         navigate(`/company/${come_back}`, { replace: true });
    //     }
    // }
    useEffect(() => {
        if (!id && !companyId) {
            if (mainStore.companyLists && mainStore.companyLists.length)
                navigate(`/company/${mainStore.companyLists[0].id}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, companyId, mainStore.companyLists, navigate])

    const menus: IMenuItem[] = [
        {
            title: "Appearance & Files",
            link: `/${companyId}/${siteCode}/theme`,
            icon: "Paint"
        },
        {
            title: "Home Menu",
            link: `/${companyId}/${siteCode}/home-menu`,
            icon: "Home"
        },
        {
            title: "Left Menu",
            link: `/${companyId}/${siteCode}/left-menu`,
            icon: "MenuFoldOne"
        },
        {
            title: "Third party manager",
            link: `/${companyId}/${siteCode}/integration`,
            icon: "Link"
        },
        {
            title: "Onboarding",
            link: `/${companyId}/${siteCode}/onboarding`,
            icon: "Iphone"
        },
        {
            title: "Back",
            link: `/company/${companyId}`,
            icon: "Back",
            // onClick: handleClick
        },
        {
            title: "Log out",
            link: "#",
            icon: "Logout",
            onClick: logOut
        }
    ]
    return (
        <Container>
            <Typography variant="h1" color="secondary-light" display="block" className="title">AU</Typography>
            {id && mainStore.companyLists.length > 0 ?
                (companyLink?.map((company, index) => <MenuItem
                    key={index}
                    {...company}
                    selected={company.link === location.pathname}
                />)) : companyId ?
                    (
                        menus.map((menu, index) => <MenuItem
                            key={index}
                            {...menu}
                            selected={menu.link === location.pathname}
                        />)
                    ) :
                    (
                        companyLink?.map((company, index) => <MenuItem
                            key={index}
                            {...company}
                            selected={company.link === location.pathname}
                        />)
                    )
            }

        </Container>
    )

}

export default observer(LeftMenu);

const Container = styled.div`
    width: 300px;
    height: 100%;
    box-sizing: border-box;
    background-color: ${ThemeColors['success-main']};

    .title {
        margin: 20px !important;
    }

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background-color: ${ThemeColors['success-main']};
        height: 40px;
        margin: 10px 0px 10px 10px;
        padding: 0px 10px;
        border-radius: 22px 0px 0px 22px;

        &.selected {
            background-color: ${ThemeColors['secondary-light']} !important;
        }

        &:hover {
            background-color: ${ThemeColors['success-dark']};
        }

        transition: all 0.5s ease;

        &:last-child {
            position: absolute;
            bottom: 3%;
            display: flex;
        }
    }
    a:last-child {
        position: absolute;
        bottom: 3%;
        display: flex;
    }
    .logout-text {
        color: white;
        font-size: 19px;
        font-weight: 600;
        font-family: monospace;
        letter-spacing: -1.3px;
        cursor: pointer;
    }
    .logout-style {
        margin-left: 20px;
    }
`;