import { useState, useEffect } from "react";
import { Typography, InputBox, Button } from 'awantunai-design-system';
import { styled } from "styled-components";
import { observer } from 'mobx-react';
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import MainStore from "store/MainStore";
import { Container } from '../../components/styleComponent/Container';
import { ICompanyInfor, IOnChangeEvent } from "interfaces";
import Loading from "components/general/Loading";
import { validateEmail, validateCode, validatePassword } from "../../constants";
import AlertStore from "store/AlertStore";
interface FormType {
    code: number | "";
    logo: string;
    name: string;
    phone: string;
    email: string;
    password?: string;
    address: string;
    postcode: string;
    websiteUrl: string;
    bookingUrl: string;
    tutorialVideo: string;
    location: {
        lat: string;
        lon: string;
    }
    managerName: string;
}

const CompanyEditView = () => {
    const loading = MainStore.loading;
    const [formData, setFormData] = useState<FormType>({
        code: '',
        name: '',
        phone: '',
        email: '',
        password: '',
        address: '',
        postcode: '',
        websiteUrl: '',
        bookingUrl: '',
        tutorialVideo: '',
        location: {
            lat: '',
            lon: '',
        },
        logo: '',
        managerName: '',
    });
    const company_data: ICompanyInfor[] = MainStore.companyLists;
    let companyInfor = {} as ICompanyInfor;
    const { id } = useParams();
    const navigate = useNavigate();
    const isEditing = id && id !== 'add';
    const isNotEditing = id && id === 'add';
    const [errorAlert, setErrorAlert] = useState({ code: '', name: '', email: '', password: '', address: '', phone: '', postcode: '' });

    useEffect(() => {
        if (isNotEditing) {
            setFormData({
                code: '',
                name: '',
                phone: '',
                email: '',
                password: '',
                address: '',
                postcode: '',
                websiteUrl: '',
                bookingUrl: '',
                tutorialVideo: '',
                location: {
                    lat: '',
                    lon: '',
                },
                logo: 'https://www.bcquake.ca/wp-content/uploads/2018/02/Sample-Logo.jpg',
                managerName: '',
            });
        }
        if (isEditing && company_data.length > 0) {
            for (var i = 0; i < company_data.length; i++) {
                if (id === company_data[i].id) {
                    setFormData({
                        ...company_data[i],
                        code: Number(company_data[i].code),
                        password: '',
                    });
                    setErrorAlert({ code: '', name: '', email: '', password: '', address: '', phone: '', postcode: '' });
                }
            }
        }
    }, [isEditing, isNotEditing, id, company_data]);

    const onChangeValue = (e: any) => {
        let tname = e.target.name
        let tvalue = e.target.value
        if (tname === 'lat' || tname === 'lon') {
            tvalue = {
                ...formData.location,
                [tname]: tvalue
            }
            tname = 'location'
        }
        setFormData({ ...formData, [tname]: tvalue });
        updateErrorMessage(e.target.name, '');
    }

    const updateErrorMessage = (key: string, value: string) => {
        setErrorAlert((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const onUpdate = async () => {
        if (isEditing) {
            companyInfor = { ...formData, code: String(formData.code) } as ICompanyInfor;
        }
        if (!formData.code) {
            updateErrorMessage('code', 'Invalid companyCode');
        }
        if (!validateCode(formData.code)) {
            updateErrorMessage('code', 'Must be 5 digits');
        }
        if (!formData.password) {
            updateErrorMessage('password', 'Invalid password');
        }
        if (!validatePassword(formData.password)) {
            updateErrorMessage('password', 'It must contain uppercase letters, numbers, and special characters (at least 8 digits).');
        }
        if (!validateEmail(formData.email)) {
            updateErrorMessage('email', 'Not email format');
        }
        if (!formData.code || !formData.password || !validateCode(formData.code) || !validatePassword(formData.password) || !validateEmail(formData.email)) return;
        if (id) {
            MainStore.updateCompanyInfor(companyInfor, id);
        }
    }

    const onAdd = async () => {
        if (isNotEditing) {
            companyInfor = { ...formData, code: String(formData.code) } as ICompanyInfor
        }
        if (!formData.code) {
            updateErrorMessage('code', 'Invalid companyCode');
        }
        if (!validateCode(formData.code)) {
            updateErrorMessage('code', 'Must be 5 digits');
        }
        if (!formData.password) {
            updateErrorMessage('password', 'Invalid password');
        }
        if (!validatePassword(formData.password)) {
            updateErrorMessage('password', 'It must contain uppercase letters, numbers, and special characters (at least 8 digits).');
        }
        if (!formData.phone) {
            updateErrorMessage('phone', 'Invalid phone');
        }
        if (!formData.address) {
            updateErrorMessage('address', 'Invalid address');
        }
        if (!formData.email) {
            updateErrorMessage('email', 'Invalid email');
        }
        if (!validateEmail(formData.email)) {
            updateErrorMessage('email', 'Not email format');
        }
        if (!formData.postcode) {
            updateErrorMessage('postcode', 'Invalid postcode');
        }
        if (!formData.name) {
            updateErrorMessage('name', 'Invalid Name');
        }
        if (!formData.code || !formData.name || !formData.password || !formData.phone || !formData.address || !formData.email || !formData.postcode || !validateCode(formData.code) || !validatePassword(formData.password) || !validateEmail(formData.email)) return;
        if (id) {
            return await MainStore.createCompanyInfor(companyInfor).then((res: any) => {
                MainStore.fetchCompany();
            });
        }
    }

    const onDelete = async () => {
        AlertStore.show({
            "title": "Delete Company",
            "message": "Are you sure you want to delete this company?",
            "cancelText": "Cancel",
            "confirmText": "Yes, Delete it",
            "onConfirm": () => {
                MainStore.deleteCompanyInfor(String(formData.code)).then((res: any) => {
                    MainStore.fetchCompany();
                    navigate('/company', { replace: true });
                });
            }
        });
    }

    return (
        <Container>
            <Typography variant="h3" mb={15}>Company Edit</Typography>
            <div className="scroll-view">
                <div className="section">
                    <Row>
                        <Col md="48%">
                            <InputBox
                                label="Company Code"
                                name='code'
                                value={formData.code}
                                helpText={String(errorAlert.code)}
                                status={errorAlert.code !== '' ? 'error' : 'normal'}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                label="Name"
                                name='name'
                                value={formData.name}
                                helpText={errorAlert.name}
                                status={errorAlert.name !== '' ? 'error' : 'normal'}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="48%">
                            <InputBox
                                label="Phone"
                                name='phone'
                                value={formData.phone}
                                helpText={errorAlert.phone}
                                status={errorAlert.phone !== '' ? 'error' : 'normal'}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                label="Email"
                                name='email'
                                type="email"
                                helpText={errorAlert.email}
                                status={errorAlert.email !== '' ? 'error' : 'normal'}
                                value={formData.email}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="48%">
                            <InputBox
                                label="Password"
                                name='password'
                                type="password"
                                value={formData.password}
                                helpText={errorAlert.password}
                                status={errorAlert.password !== '' ? 'error' : 'normal'}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                label="Adress"
                                name='address'
                                value={formData.address}
                                helpText={errorAlert.address}
                                status={errorAlert.address !== '' ? 'error' : 'normal'}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="48%">
                            <InputBox
                                label="PostCode"
                                name='postcode'
                                value={formData.postcode}
                                helpText={errorAlert.postcode}
                                status={errorAlert.postcode !== '' ? 'error' : 'normal'}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                label="Website Url"
                                name='websiteUrl'
                                value={formData.websiteUrl}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="48%">
                            <InputBox
                                label="Booking Url"
                                name='bookingUrl'
                                value={formData.bookingUrl}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                label="Tutorial Video"
                                name='tutorialVideo'
                                value={formData.tutorialVideo}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="100%">
                            <Typography variant="subtitle3">Location</Typography>
                        </Col>
                        <Col md="48%">
                            <InputBox
                                name="lat"
                                label="Lat"
                                type="text"
                                value={formData.location.lat}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                name="lon"
                                label="Lon"
                                type="text"
                                value={formData.location.lon}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="48%">
                            <InputBox
                                name="logo"
                                label="Logo"
                                type="text"
                                disabled={isNotEditing ? true : false}
                                value={formData.logo}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                        <Col md="48%">
                            <InputBox
                                name="managerName"
                                label="Manager Name"
                                value={formData.managerName}
                                onChange={(e: IOnChangeEvent) => onChangeValue(e)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer>
                <Button
                    title="Save"
                    onClick={isNotEditing ? onAdd : onUpdate}
                    size="small"
                    icon="SaveOne"
                    variant="primary"
                />
                <Button
                    title="Delete"
                    onClick={onDelete}
                    size="small"
                    icon="Delete"
                    variant="secondary"
                />
            </Footer>
            <Loading isloading={loading} />
        </Container>
    )
}

export default observer(CompanyEditView);

const Footer = styled.div`
    display: flex;
    gap: 10px;
`

const Row = styled.div`
    --bs-gutter-x: 20px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)*-1);
    margin-right: calc(var(--bs-gutter-x)*-0.5);
    margin-left: calc(var(--bs-gutter-x)*-0.5);
    .alert {
        position:absolute;
        right:15px;
        bottom:13px;
    }
}
`
const Col = styled.div < { md: string }> `
    flex: 0 0 auto;
    width: ${props => (props.md)};
    margin-left:20px;
    margin-top:20px;
`;