import axios, { AxiosInstance } from "axios";
import {
  ICompanyInfor,
  IIntegration,
  IMenu,
  IOnboardings,
  ITheme,
  LoginRequest,
} from "interfaces";

class AUApi {
  private baseUrls: { [id: string]: string } = {
    PRODUCTION: "https://us-central1-hendy-production.cloudfunctions.net/v1",
    LOCAL: "http://localhost:5001/hendy-production/us-central1/v1",
    LIVE: "https://europe-west2-hendy-hosting.cloudfunctions.net/v1",
  };

  instance: AxiosInstance;
  server: string = "PRODUCTION";

  constructor(server: string = "PRODUCTION") {
    this.server = server;
    this.instance = axios.create({
      baseURL: this.baseUrls[server],
      // timeout: 30000
    });
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      this.instance.defaults.headers.common["Authorization"] =
        "Bearer " + token;
    }
    this.instance.interceptors.response.use(
      (response) => response.data,
      (error) => Promise.reject(error)
    );
  }

  init() {
    this.instance = axios.create({
      baseURL: this.baseUrls[this.server],
      // timeout: 30000
    });

    this.instance.interceptors.response.use(
      (response) => response.data,
      (error) => Promise.reject(error)
    );
  }

  setToken(token: string) {
    this.init();
    this.instance.interceptors.request.use((config) => {
      const headers = config.headers || {};
      // @ts-ignore
      config.headers = { ...headers, Authorization: `Bearer ${token}` };
      return config;
    });
  }
  /******************
   *     Login      *
   ******************/
  fetchAuthUser(logindata: LoginRequest) {
    return this.instance.post("/auth/company", logindata);
  }

  fetchProfile() {
    return this.instance.get("/auth/profile");
  }

  /******************
   *     Theme      *
   ******************/
  fetchTheme(companyId: string, siteCode: string) {
    return this.instance.get(`/settings/theme/${companyId}/${siteCode}`);
  }

  updateTheme(theme: ITheme, companyId: string, siteCode: string) {
    return this.instance.post("/settings/theme", {
      theme,
      companyId,
      siteCode,
    });
  }

  /******************
   *     Menus      *
   ******************/
  fetchMenus(companyId: string, siteCode: string) {
    return this.instance.get(`/settings/menu/${companyId}/${siteCode}`);
  }

  updateMenus(menus: IMenu, companyId: string, siteCode: string) {
    return this.instance.post("/settings/menu", { menus, companyId, siteCode });
  }

  /******************
   *     Services   *
   ******************/
  fetchServices() {
    return this.instance.get("/settings/services");
  }

  updateServices(services: any) {
    return this.instance.post("/settings/services", services);
  }

  /******************
   *     Companies   *
   ******************/
  fetchCompany() {
    return this.instance.get("/companies/");
  }

  fetchSites(code: string) {
    return this.instance.get(`/sites/companyCode/${code}`);
  }

  updateCompanyInfor(company: ICompanyInfor, id: string) {
    return this.instance.put(`/companies/${id}`, company);
  }

  createCompanyInfor(company: ICompanyInfor) {
    return this.instance.post("/companies", company);
  }

  deleteCompanyInfor(code: string) {
    return this.instance.delete(`/companies/${code}`);
  }

  addSite(site: any) {
    return this.instance.post("/sites", site);
  }

  editSite(site: any, id: string) {
    return this.instance.put(`/sites/${id}`, site);
  }

  deleteSite(id: string) {
    return this.instance.delete(`sites/${id}`);
  }

  /******************
   *     Integrations   *
   ******************/
  fetchIntegrations(companyId: string, siteCode: string) {
    return this.instance.get(`/settings/integration/${companyId}/${siteCode}`);
  }

  createIntegration(
    integrations: IIntegration[],
    companyCode: string,
    siteCode: string
  ) {
    return this.instance.post("/settings/integration", {
      integrations,
      companyCode,
      siteCode,
    });
  }

  /******************
   *     Onboardings   *
   ******************/
  fetchOnboarding(companyId: string, siteCode: string) {
    return this.instance.get(
      `/settings/siteonboarding/${companyId}/${siteCode}`
    );
  }

  createOnboarding(
    onboardings: IOnboardings,
    companyId: string,
    siteCode: string
  ) {
    return this.instance.post("/settings/siteonboarding", {
      onboardings,
      companyId,
      siteCode,
    });
  }
}

const aUApi = new AUApi('PRODUCTION');
export default aUApi;
