import { styled } from "styled-components";
import { DragDrop } from "awantunai-design-system";
interface UploadProps {
    onChange: (url: string) => void;
    url?: string;
    label: string;
    helpText?: string;
    uploading?: boolean;
    style?: any;
}

const UploadImagePicker = ({
    url,
    label,
    helpText,
    onChange,
    uploading,
    style
}: UploadProps) => {
    return (
        <Container>
            <DragDrop
                helpText={helpText || "Max size 5MB | PNG, JPG, JPEG"}
                label={label}
                maxSize={5}
                minSize={0}
                onChange={(file: any) => {
                    onChange(file);
                }}
                value={url}
                placeholder="Drag & drop file here"
                types={['png', 'jpg', 'jpeg', 'json']}
                style={style}
                loading={uploading}
            />
        </Container>
    )
}

export default UploadImagePicker;

const Container = styled.div`
    display: inline-block;
    margin: 10px;
`;